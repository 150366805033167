import Spaceship        from 'foundations/layout/spaceship.jpg';
import {Button}         from 'foundations/button/button';
import {useTranslation} from 'foundations/i18n/i18n';

export function EapSignupCta() {
    const {t} = useTranslation();
    return (
        <div className={'site-width'}>
            <div className={'py-16 rounded-xl bg-top bg-cover'} style={{
                backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8) ), url(${Spaceship.src})`
            }}>
                <div className={'site-width flex justify-center'}>
                    <div className={'max-w-5xl flex flex-col items-center'}>
                        <h1 className={'font-bold leading-tight text-4xl lg:text-6xl lg:text-center mb-2 text-white'}>{t('Go for Launch.')}</h1>
                        <span className={'lg:text-center block text-2xl text-white mb-6'}>{t('Your team is better without all the headaches. Unlock their potential.')}</span>
                        <Button href={'https://app.withglimpse.com'}>{t('Start now')}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}