import Link                   from 'next/link';
import {Icon}                 from '@glimpse/glass';
import {useTranslation}       from 'foundations/i18n/i18n';
import {EapSignupCta}         from 'features/shared/eap-signup-cta/eap-signup-cta';
import {Button}               from 'foundations/button/button';
import {AnchorHTMLAttributes} from 'react';

export function Footer() {
    const {t} = useTranslation();
    return (
        <>
            <EapSignupCta/>
            <footer className={'py-8 pt-12 border-solid border-0 border-t border-slate-100'}>
                <div className={'site-width'}>
                    <div className={'grid gap-8 md:gap-0 grid-cols-1 md:grid-cols-2 mb-4'}>
                        <div className={'shrink-1'}>
                            <span className={'block text-xl font-bold mb-1'}>{t('We\'re here to help.')}</span>
                            <span className={'block mb-2 max-w-md'}>{t('Need a hand or want to talk to sales? Give us a shout and someone will be happy to help.')}</span>
                            <Button href={'/support'}>{t('Contact Us')}</Button>
                        </div>
                        <div className={'grid grid-cols-2 gap-2 md:grid-cols-3 mb-4'}>
                            <div>
                                <span className={'block font-bold mb-2'}>{t('Company')}</span>
                                <Item href={'/about'}>{t('About')}</Item>
                                <Item href={'/support'}>{t('Contact us')}</Item>
                                <Item href={'https://forms.fillout.com/t/3t6AhMnNeaus'} target={'_blank'}>{t('Feedback')}</Item>
                            </div>
                            <div>
                                <span className={'block font-bold mb-2'}>{t('Product')}</span>
                                <Item href={'/features'}>{t('Features')}</Item>
                                <Item href={'/pricing'}>{t('Pricing')}</Item>
                                <Item href={'/integrations'}>{t('Integrations')}</Item>
                            </div>
                            <div>
                                <span className={'block font-bold mb-2'}>{t('Resources')}</span>
                                <Item href={'/support'}>{t('Support')}</Item>
                                <Item href={'/terms'}>{t('Terms')}</Item>
                                <Item href={'/privacy'}>{t('Privacy')}</Item>
                                <Item href={'/cookies'}>{t('Cookies')}</Item>
                            </div>
                        </div>
                    </div>
                    <div className={'border-t pt-6 block md:flex justify-between'}>
                        <div className={'flex gap-3 text-g-secondary mb-4 lg:mb-0'}>
                            <Link href={'mailto:team@withglimpse.com'} className={'hover:text-g-secondary'}>
                                <Icon fontSize={2} fa={['fas', 'fa-envelope']}/>
                                <span className={'ml-0.5 font-bold inline-block text-sm'}>{'Email'}</span>
                            </Link>
                            <a href={'https://github.com/glimpsesoftware'} target={'_blank'} className={'hover:text-g-secondary'}>
                                <Icon fontSize={2} fa={['fab', 'fa-github']} className={'align-baseline'}/>
                                <span className={'ml-0.5 font-bold inline-block text-sm'}>{'Github'}</span>
                            </a>
                            <a className={'hover:text-g-secondary'} href={'https://www.linkedin.com/company/glimpsesoftware'}>
                                <Icon fontSize={2} fa={['fab', 'fa-linkedin']} className={'align-baseline'}/>
                                <span className={'ml-0.5 font-bold inline-block text-sm align-baseline'}>{'LinkedIn'}</span>
                            </a>
                        </div>
                        <p className={'text-xs text-slate-600'}>{`©${new Date().getFullYear()} • Glimpse Software Corporation. All Rights Reserved.`}</p>
                    </div>
                </div>
            </footer>
        </>
    );
}

const Item = ({
    href,
    target = '_self',
    children
}: {
    href: string,
    target?: AnchorHTMLAttributes<any>['target']
    children: string
}) => {
    return (
        <Link className={'block mb-1 hover:text-g-secondary'} href={href} target={target}>{children}</Link>
    );
};