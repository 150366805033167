'use client';

import React                                                     from 'react';
import {Header}                                                  from 'foundations/layout/header';
import {Footer}                                                  from 'foundations/layout/footer';
import {FontawesomeClientLoader, FontawesomeServerLoader, Glass} from '@glimpse/glass';
import {fal}                                                     from '@fortawesome/pro-light-svg-icons';
import {fad}                                                     from '@fortawesome/pro-duotone-svg-icons';
import {fas}                                                     from '@fortawesome/pro-solid-svg-icons';
import {far}                                                     from '@fortawesome/pro-regular-svg-icons';
import {fab}                                                     from '@fortawesome/free-brands-svg-icons';
import 'app/layout.css';
import '@glimpse/glass/build/main.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {PosthogProvider}                                         from 'providers/posthog/posthog-provider';
import {Inter}                                                   from 'next/font/google';
import Script                                                    from 'next/script';

PosthogProvider.init();

const inter = Inter({
    subsets: ['latin'],
    display: 'swap'
});

export default function RootLayout({
    children
}: {
    children: React.ReactNode
}) {
    return (
        <html>
        <head title={'Glimpse'}>
            {/* Theming */}
            <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png?v=2'/>
            <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png?v=2'/>
            <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png?v=2'/>
            <link rel='manifest' href='/site.webmanifest'/>
            <link rel='canonical' href='https://withglimpse.com/'/>
            <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#7c5cff'/>
            <meta name='msapplication-TileColor' content='#f5f2f2'/>
            <meta name='theme-color' content='#f5f2f2'/>

            {/* Hubspot tracking */}
            <Script type='text/javascript' id='hs-script-loader' async defer src='//js.hs-scripts.com/45144410.js'/>

            {/* Google Analytics & Ads */}
            <Script async src='https://www.googletagmanager.com/gtag/js?id=AW-16456821756'/>
            <Script id={'google-analytics'} dangerouslySetInnerHTML={{
                __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {
                        window.dataLayer.push(arguments);
                    }
                    window.gtag('js', new Date());
                    window.gtag('config', 'AW-16456821756');`
            }}/>

            {/* LinkedIn Tracking Part 1 */}
            <Script id={'linked-in-tracking-1'} type='text/javascript' dangerouslySetInnerHTML={{
                __html: `
                        _linkedin_partner_id = "6398076";
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                    `
            }}/>
            {/* LinkedIn Tracking Part 2 */}
            <Script id={'linked-in-tracking-2'} type='text/javascript' dangerouslySetInnerHTML={{
                __html: `
                        (function(l) {
                        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                        window.lintrk.q=[]}
                        var s = document.getElementsByTagName("script")[0];
                        var b = document.createElement("script");
                        b.type = "text/javascript";b.async = true;
                        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                        s.parentNode.insertBefore(b, s);})(window.lintrk);
                    `
            }}/>

            {/* RB2B */}
            <Script id={'rb2b'} dangerouslySetInnerHTML={{
                __html: `
                        !function () {var reb2b = window.reb2b = window.reb2b || [];
                        if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];
                        reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);
                        args.unshift(method);reb2b.push(args);return reb2b;};};
                        for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}
                        reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;
                        script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";
                        var first = document.getElementsByTagName("script")[0];
                        first.parentNode.insertBefore(script, first);};
                        reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("961Y0HXLGGNG");}();
                        `
            }}/>
        </head>
        <body className={inter.className}>
        <FontawesomeClientLoader packs={[fal, far, fas, fab, fad]}/>
        <FontawesomeServerLoader packs={[fal, far, fas, fab, fad]}/>
        <Glass>
            <main>
                <Header/>
                {children}
                <Footer/>
            </main>
        </Glass>
        </body>
        </html>
    );
}